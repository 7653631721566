import 'whatwg-fetch'
import {baseUrl} from '../../config.js'
let basePath=baseUrl+"front/";

function checkStatus(response) {
  if(response.status===401){
    alert("请先登录！")
    window.location.href='/login'
    return
  }
  if (response.status >= 200 && response.status < 400) {
    return response
  } else {
    let error = new Error(response.statusText)
    error.response = response
    throw error
  }
}
function objecttostring(obj) {
  let str = [];
  for(let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
export default {
  getData:function (url='',success=function () {},fail=function () {}) {
    fetch(basePath+url,{
      method: 'GET',
      credentials: 'include',
      headers:{
        Authorization: window.sessionStorage.getItem("token") || ''
      }
    })
      .then(checkStatus)
     .then(function(response) {
       response.json().then(success)
    }).catch(function(ex) {
      console.log('失败！！')
      ex.response.json().then(fail)
    })
  },
  postData:function (url='',data={},success=function () {},fail=function () {}) {
    fetch(basePath+url,{
      method: 'POST',
      credentials: 'include',
      body:objecttostring(data),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: window.sessionStorage.getItem("token") || ''
      },
    })
      .then(checkStatus)
      .then(function(response) {
        response.json().then(success)
      }).catch(function(ex) {
      console.log('失败！！');
      ex.response.json().then(fail)
    })
  },
}
