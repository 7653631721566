<template>
    <el-table
            :data="orderList"
            stripe
            style="width: 100%; background-color: rgba(0, 0, 0, 0.4); "
    >
        <el-table-column
                prop="id"
                label="报名号"
                width="180">
        </el-table-column>
        <el-table-column  label="日期" >
            <template slot-scope="scope">
                <span>{{scope.row.year}}年{{scope.row.month}}月{{scope.row.day}}日</span>
            </template>
        </el-table-column>
        <el-table-column
                prop="desc"
                label="名称"
                width="180">
        </el-table-column>
        <el-table-column
                prop="statusdesc"
                label="状态">
        </el-table-column>
        <el-table-column align="center" label="操作" >
            <template slot-scope="scope">
                <el-button type="danger" @click="$router.push( {name: 'Order', params: { id:scope.row.id}})">
                    <span v-if="scope.row.status=='1'">去支付或取消</span>
                    <span v-else style="color: #0a3122">查看报名详情</span>
                </el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
    export default {
        name: "History",
        data() {
            return {
                orderList:[],
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1517 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1516 弄'
                }]
            }
        },
        mounted() {
            this.$http.getData('order',(data)=>{
                this.orderList=data
            },(data)=>{
                this.$message.error(data.message)
            })
        }

    }

</script>

<style scoped>

</style>