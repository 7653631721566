import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Information from "../views/Information";
import Question from "../views/Question";
import Register from "../views/Register";
import History from "../views/History"
import Order from "../views/Order";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
 {
    path:'/login',
    name:"Login",
    component:Login
  },{
    path:'/information',
    name:"Information",
    component:Information,
  },{
    path:'/register',
    name:"Register",
    component:Register
  },{
    path:'/question',
    name:"Question",
    component:Question
  },{
    path:'/history',
    name:"History",
    component:History
  },{
    path: '/order/:id',
    name: 'Order',
    component: Order,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   let token=false
//   if( /token=.{30,};*?/.test(document.cookie)){
//     token=true
//   }
//   let name = to.name;
//   if (name === 'Login' || name==="Home") {
//     next();
//     return;
//   }
//   if (token) {
//     next();
//   } else {
//     alert("请先登录！")
//     next({
//       path: '/login'
//     })
//   }
// })


export default router
