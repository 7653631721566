<template>
    <div>
    <div class="form-wrap3">
        <table class="form">
            <tr>
                <td>报名号</td><td>{{order.id}}</td>
            </tr>
            <tr>
                <td>比赛</td><td>{{order.year}}年{{order.month}}月{{order.day}}日{{order.desc}}</td>
            </tr>
<!--            <tr>-->
<!--                <td>当前状态</td><td style="color:#ff6f00;font-size: 25px;font-weight: bold">{{order.statusdesc}}-->
<!--                <span v-if="order.status==2">你已经报名成功，请下载报名表</span>-->
<!--                <span v-if="order.status==5">请点击上方常见问题联系客服</span>-->
<!--                 <span v-if="order.status==6"> 正在处理你的退款申请</span>-->

<!--            </td>-->
<!--            </tr>-->


            <tr>
                <td>报名费</td><td style="color:#ff6f00;font-size: 25px;font-weight: bold">{{order.amount}}人民币</td>
            </tr>
            <tr>
                <td>分组</td><td>{{order.group_name}}</td>
            </tr>
            <tr>
                <td>开始时间：</td><td>{{order.group_time}}</td>
            </tr>
            <tr v-if="order.cert && order.status == 2">
                <td>
                    报名表
                </td>
                <td>
                    <a :href="baseUrl+'front/orderpdf/'+order.cert"   target="_blank" >点击下载</a>
                </td>
            </tr>
          <tr v-if="order.status==2">
            <td>
              退款申请
            </td>

            <td>
              <el-button  type="warning" icon="el-icon-search" @click="Qdialog=true">点击申请退款</el-button>
            </td>
          </tr>
        </table>

      <el-dialog title="退款申请" :visible.sync="Qdialog" :close-on-click-modal="false">
        <p>退款申请后我们将在5个工作日内处理你的申请</p>

        <p>  退款理由: <input v-model="paybackReason"> </input>   </p>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="payback">申请退款</el-button>
          <el-button @click="Qdialog = false">关闭对话框</el-button>
        </div>
      </el-dialog>
        <br>
        <br>

      <p>请使用微信扫描下方二维码进行支付，请务必备注你的名字加上手机号的后4位，例如张三1111，并保证金额准确，支付成功后即报名成功 </p>
      <img src="~@/assets/wechat.png" />
<!--        <template v-if="order.status===1">-->
<!--&lt;!&ndash;            <p>如果支付多次失败，请点击取消报名后重新报名</p>&ndash;&gt;-->
<!--&lt;!&ndash;            <el-button style="height:40px;width: 100px;margin-right: 50px" type="success" @click="cancel" >取消报名</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;            <el-button  style="height:40px;width: 300px;margin: 0;"  type="warning"  @click="alipay">使用支付宝支付</el-button>&ndash;&gt;-->

<!--          -->
<!--        </template>-->

    </div>
    </div>
</template>

<script>
    import {baseUrl} from '../../config'
    export default {
        name: "Order",
        data(){
            return{
                baseUrl:baseUrl,
                order:{},
                timer:{},
              Qdialog:false,
              paybackReason:""
            }
        },
        methods:{
            getData(){
                this.$http.getData('order/'+this.$route.params.id,(data)=>{
                    this.order=data
                    // if(data.status===1){
                    //     this.timer=setTimeout(()=>{
                    //         this.getData();
                    //     },1000)
                    // }
                },(data)=>{
                    this.$message.error(data.message)
                })
            },
          payback:function (){
            this.$http.postData('orderpayback/'+this.$route.params.id,{
              paybackReason:this.paybackReason
            },(data)=>{
              this.$message.success(data.message)
              this.Qdialog = false
              this.getData()
            },(data)=>{
              this.$message.error(data.message)
            })
          },
          cancel:function() {
              this.$http.postData('ordercancel/'+this.$route.params.id,{},(data)=>{
                  this.$message.success(data.message)
                  this.getData()
              },(data)=>{
                  this.$message.error(data.message)
              })
          },
            alipay:function() {
                this.$http.postData('orderalipay/'+this.$route.params.id,{},(data)=>{
                    window.open(data.url, "_blank");
                },(data)=>{
                    this.$message.error(data.message)
                })
            }
        },
        mounted() {
            this.getData()
        },
        destroyed() {
            clearTimeout(this.timer)
        }
    }

</script>

<style scoped lang="scss">
    .form-wrap3{
        position: relative;
        text-align: center;
        .avatar{
            margin: 10px;
            img{
                width: 200px;
            }
            position: absolute;
            left: 650px;

        }
        .form{
            font-size: 15px;
            text-align: start;
            display: inline-block;
            td{
                padding: 10px;
            }
        }
    }
</style>