<template>
    <div class="login">
        <el-card  class="login-card">
            <h2>没有账号？请注册</h2>
            <el-form class="login-form" @submit.native.prevent="login" :model="registerForm" :rules="registerRule">
                <el-form-item prop="username">
                    <el-input v-model="registerForm.username" placeholder="请输入手机号" prefix-icon="fas fa-mobile-alt"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="registerForm.password" placeholder="请输入密码，不能是纯数字" type="password" ref="" prefix-icon="fas fa-lock"></el-input>
                </el-form-item>
                <el-form-item prop="rePassword">
                    <el-input v-model="registerForm.rePassword" placeholder="请再次输入密码" type="password" prefix-icon="fas fa-lock"></el-input>
                </el-form-item>
                <el-form-item prop="name">
                    <el-input v-model="registerForm.name" placeholder="请输入报名人员姓名" prefix-icon="fas fa-user"></el-input>
                </el-form-item>
                <el-form-item prop="idNumber">
                    <el-input v-model="registerForm.idNumber"  placeholder="请输入报名人员身份证号" prefix-icon="fas fa-id-card"></el-input>
                </el-form-item>
                <el-form-item prop="checkCode">
                    <el-col :span="12">
                        <el-input v-model="registerForm.checkCode" placeholder="请输入验证码" prefix-icon="fas fa-check-circle"></el-input>
                    </el-col>
                    <el-col :span="12">
                        <el-button block :disabled="checkCodeBtn!=='点击获得验证码'" @click="sms(registerForm.username)">{{checkCodeBtn}}</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-button  class="login-button" @click="register">注册</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card class="login-card">
            <h2>已有账号？请登录</h2>
            <el-form class="login-form" :model="loginForm" :rules="loginRule">
                <el-form-item prop="username">
                    <el-input v-model="loginForm.username" placeholder="请输入手机号" prefix-icon="fas fa-mobile-alt"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input v-model="loginForm.password" placeholder="请输入密码" type="password" prefix-icon="fas fa-lock"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button @click="login" class="login-button">登录</el-button>
                </el-form-item>
                <a class="forgot-password" @click="dialog = true">忘记密码 ?</a>
            </el-form>
        </el-card>
        <el-dialog title="修改密码" :visible.sync="dialog" :close-on-click-modal="false" width="500px">
            <el-form >
                <el-form-item >
                    <el-input v-model="rePasswordForm.username" placeholder="请输入手机号" prefix-icon="fas fa-mobile-alt"></el-input>
                </el-form-item>
                <el-form-item >
                    <el-col :span="12">
                        <el-input v-model="rePasswordForm.checkCode"  prefix-icon="fas fa-check-circle" placeholder="请输入验证码" ></el-input>
                    </el-col>
                    <el-col :span="12">
                        <el-button style="width: 100%" block :disabled="checkCodeBtn!=='点击获得验证码'" @click="sms(rePasswordForm.username)">{{checkCodeBtn}}</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item >
                    <el-input v-model="rePasswordForm.password" prefix-icon="fas fa-lock" placeholder="请输入新密码，长度大于7位，不能是纯数字" ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button  class="login-button" @click="rePassword">点击修改密码</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialog = false">关闭对话框</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                dialog:false,
                timer:{},
                loginForm: {
                    username: "",
                    password: ""
                },
                rePasswordForm:{
                   username:"",
                   password:"",
                   checkCode:""
                },
                registerForm: {
                    username: "",
                    password: "",
                    rePassword:"",
                    name:"",
                    idNumber:"",
                    checkCode:""
                },
                checkCodeBtn:"点击获得验证码",
                registerRule: {
                    username: [
                        {required: true, message: "必须输入手机号", trigger: "blur"},
                        {pattern:"^admin|([1][3,4,5,7,8][0-9]{9})$", message: "手机号格式不正确", trigger: "blur"}
                    ],
                    password: [
                        {required: true, message: "必须输入密码", trigger: "blur" },
                        {min: 7, message: "密码长度必须超过7位",}
                    ],
                    rePassword: [
                        { required: true, message: "必须再次输入密码", trigger: "blur" },
                    ],
                    name: [
                        { required: true, message: "必须输入姓名", trigger: "blur" },
                    ],
                    idNumber: [
                        { required: true, message: "必须输入身份证号", trigger: "blur" },
                        {pattern:"^[1-9]\\d{5}(18|19|20|(3\\d))\\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$", message: "身份证格式不正确", trigger: "blur"}
                    ],
                    checkCode: [
                        { required: true, message: "必须输入验证码", trigger: "blur" },
                        {pattern:"^\\d{6}$", message: "验证码为六位数字", trigger: "blur"}
                    ]
                },
                loginRule: {
                    username: [
                        {required: true, message: "必须输入手机号",},
                        {pattern:"^admin|([1][3,4,5,7,8][0-9]{9})$", message: "手机号格式不正确", trigger: "blur"}
                    ],
                    password: [
                        { required: true, message: "必须输入密码", trigger: "blur" },
                        {min: 7, message: "密码长度必须超过7位", trigger: "blur"}
                    ]
                }
            };
        },
        methods: {
            register(){
              if(this.registerForm.password!==this.registerForm.rePassword) {
                  this.$message.error("两次密码输入不一致");
                  return
              }
                this.$http.postData("register",this.registerForm,
                    (data)=>{
                        this.$message.success("你已经注册成功，3秒后自动登录")
                        setTimeout(()=>{
                            this.$bus.emit("loginsuccess",data)
                        },2000)
                    },
                    (data)=>{
                        this.$message.error(data.message);
                    }
                )
            },
            rePassword(){
                this.$http.postData("register/repassword",this.rePasswordForm,
                    (data)=>{
                       this.$message.success("重置密码成功，请登录")
                        this.dialog=false
                    },
                    (data)=>{
                        this.$message.error(data.message);
                    }
                )
            },
            login(){
                this.$http.postData("login",this.loginForm,
                    (data)=>{
                        this.$bus.emit("loginsuccess",data)
                    },
                    (data)=>{
                        this.$message.error(data.message);
                    }
                )
            },
            sms(username){
                this.$http.postData("register/sms",{username},
                    (data)=>{
                        this.$message.success("成功发送验证码")
                        let time=60
                        this.checkCodeBtn=time+"秒后重试"
                        this.timer=setInterval( ()=> {
                            this.checkCodeBtn=time+"秒后重试"
                            if(time===0){
                                this.checkCodeBtn="点击获得验证码"
                                clearInterval(this.timer)
                            }
                            time--
                        },1000)
                    },
                    (data)=>{
                        this.$message.error(data.message);
                    }
                )
            },
        },

        destroyed() {
            clearInterval(this.timer)
        }

    }
</script>

<style scoped lang="scss">
    $teal: rgb(0, 124, 137);
    .login {
        text-align: center;
        flex: 1;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        .login-card{
            background:  url("../assets/bg_login_wrap-bottom.gif");
        }
    }

    .login-button {
        width: 100%;
        margin-top: 40px;
        background: $teal;
        border-color: $teal;
        &:hover,
        &.active,
        &:focus {
            background: lighten($teal, 7);
            border-color: lighten($teal, 7);
        }
        color:white;
    }
    .login-form {
        width: 290px;
    }
    .forgot-password {
        margin-top: 10px;
    }
</style>
<style lang="scss" scoped>
    $teal: rgb(0, 124, 137);
    .login .el-input__inner:hover {
        border-color: $teal;
    }
    .login .el-input__prefix {
        background: rgb(238, 237, 234);
        height: calc(100% - 2px);
        left: 1px;
        top: 1px;
        border-radius: 3px;
        .el-input__icon {
            width: 30px;
        }
    }
    .login .el-input input {
        padding-left: 35px;
    }
    .login .el-card {
        padding-top: 0;
        padding-bottom: 30px;
    }
    h2 {
        margin: 20px 0 40px 0;
        letter-spacing: 1px;
    }
    a {
        color: $teal;
        text-decoration: none;
        &:hover,
        &:active,
        &:focus {
            color: lighten($teal, 7);
        }
    }
    .login .el-card {
        width: 340px;
        display: flex;
        justify-content: center;
    }
</style>
