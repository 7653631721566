<template>
<div>
    <P style="font-size: 18px;margin-bottom: 7px">报名流程</P>
    <div class="progress">
        <div class="sub" :class="{sub_active:active(1)}">
            1、确认个人信息
        </div>
        <div class="sub" :class="{sub_active:active(2)}">
            2、确认场次
        </div>
        <div class="sub" :class="{sub_active:active(3)}">
            3、确认报名内容
        </div>
        <div class="sub" :class="{sub_active:active(4)}">
            4、支付报名费
        </div>
    </div>
    <div v-show="step===1" class="form-wrap">
<!--        <div class="avatar">-->
<!--            <img :src="'https://www.shchess.com/imgserver/'+Pform.photo">-->
<!--            <p>照片</p>-->
<!--        </div>-->
        <table class="form">
            <tr>
                <td>手机号</td><td>{{Pform.username}}</td>
            </tr>
            <tr>
                <td>报名人员姓名</td><td>{{Pform.name}}</td>
            </tr>
          <tr>
            <td>邮寄地址（必须填写）</td><td>{{Pform.address}}</td>
          </tr>
            <tr>
            <td>身份证号</td><td>{{Pform.idNumber}}</td>
             </tr>
            <tr>
                <td>单位</td><td><span v-if="Pform.company!=='其他'">{{Pform.company}}</span><span v-else>{{Pform.companya}}</span></td>
            </tr>
        </table>
        <br>
        <br>
        <br>
        <br>
        <el-button style="height:40px;width: 500px;margin-bottom: 30px" type="warning" @click="$router.push({ name: 'Information'})">信息需要修改</el-button>
        <el-button  style="height:40px;width: 500px;margin: 0"  type="primary" class="teal" @click="step++"  :disabled="!Pform.address  ">信息无误，下一步</el-button>
    </div>
    <div v-show="step===2" class="form2">
        <el-form label-width="80px" style="width: 400px">
            <el-form-item label="报名场次">
                <el-select @change="contestChange" v-model="Cform.contest_id" placeholder="请选择报名场次"  >
                    <el-option v-for="contest in contestList" :key="contest.id"  :label="contest.month+'月'+contest.day+'日'+contest.desc" :value="contest.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="报名分组">
                <el-select @change="groupChange"  :value="Gform.name">
                    <el-option v-for="group in groupList"  :value="group" :label="group.name" :key="group.name"></el-option>
                </el-select>
            </el-form-item>
            <template  v-if="Gform.name">
<!--            <el-form-item >-->
<!--                选择的分组：{{Gform.name}}-->
<!--            </el-form-item>-->
<!--            <el-form-item >-->
<!--                如果升级成功，你的下一个级别是：{{Gform.next}}-->
<!--            </el-form-item>-->
<!--            <el-form-item >-->
<!--                你的小组的开赛时间：{{Gform.time}}-->
<!--            </el-form-item>-->
              <el-form-item >
                备注：{{Gform.remark}}
              </el-form-item>
            </template>
        </el-form>
        <br>
        <br>
        <el-button style="height:40px;width: 200px;margin-bottom: 30px" type="warning" @click="step--">上一步</el-button>
        <el-button  style="height:40px;width: 200px;margin: 0;"      @click="step++"
                    :disabled="!Cform.contest_id || !Gform.name ">
            信息无误，下一步
        </el-button>
    </div>
    <div v-show="step===3" class="form-wrap3">
<!--        <div class="avatar">-->
<!--            <img :src="'https://www.shchess.com/imgserver/'+Pform.photo">-->
<!--            <p>照片</p>-->
<!--        </div>-->
        <table class="form">
            <tr>
                <td>报名场次</td><td>{{contestd.year}}年{{contestd.month}}月{{contestd.day}}日{{contestd.desc}}</td>
            </tr>
            <tr>
                <td>报名费</td><td>{{contestd.amount}}元人民币</td>
            </tr>
            <tr>
                <td>分组</td><td>{{Gform.name}}</td>
            </tr>
            <tr>
                <td>分组培训时间：</td><td>{{Gform.time}}</td>
            </tr>
            <tr>
                <td>姓名</td><td>{{Pform.name}}</td>
            </tr>
            <tr>
                <td>身份证号</td><td>{{Pform.idNumber}}</td>
            </tr>
            <tr>
                <td>手机号</td><td>{{Pform.username}}</td>
            </tr>
            <tr>
              <td>单位</td><td><span v-if="Pform.company!=='其他'">{{Pform.company}}</span><span v-else>{{Pform.companya}}</span></td>
            </tr>
            <tr>
                <td>
                    点击滑块确认：
                </td>
                <td>
                    <el-switch
                            v-model="check2"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                    </el-switch>
<!--                    我已经确认我的信息无误，我所报名的小组的等级是我当前的等级，符合报名的要求-->
                       我已经确认我的信息无误，符合报名的要求
                </td>
            </tr>
            <tr>
                <td>
                    点击滑块确认：
                </td>
                <td>
                    <el-switch
                        v-model="check"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                </el-switch>
                  我已经知晓本次培训规程：<a :href="contestd.rule"  target="_blank">点击下载并查看</a>
                </td>
            </tr>
        </table>
        <br>
        <br>
        <el-button style="height:40px;width: 200px;margin-bottom: 30px" type="warning" @click="step--">上一步</el-button>
        <el-button  style="height:40px;width: 200px;margin: 0;color:white" class="teal"  :disabled="!check || !check2" @click="order" > 确信信息无误，进行支付</el-button>
    </div>
</div>
</template>

<script>
    import {baseUrl} from '../../config.js'
    export default {
        name: "Register",
        data(){
            return{
                baseUrl:baseUrl,
                check2:false,
                check:false,
                Gform:{},
                contestList:[],
                Cform:{},
                step:1,
                Pform: {
                    username: "",
                    name:"",
                    idNumber:"",
                    photo:"admin-default-.jpg",
                    company:"",
                    companya:""
                },
            }
        },
        methods:{
            order(){
              this.$http.postData("order",{
                  contest_id:this.Cform.contest_id,
                  group_name:this.Gform.name,
                  group_time:this.Gform.time,
                  user_photo:this.Pform.photo,
                  user_company:this.Pform.company,
                  user_companya:this.Pform.companya,
              },()=>{
                  this.$message.success("操作成功，请继续完成支付");
                  this.$router.push({name:'History'})
              },(data)=>{
                  this.$message.error(data.message);
                  this.$router.push({name:'History'})
              })
            },
            active(num){
                return num <= this.step;
            },
            groupChange(e){
                this.Gform=JSON.parse(JSON.stringify(e))
            },
            contestChange(e){
                this.Gform={}
            }
        },
        computed:{
          groupList:function () {
                for(let i=0;i<this.contestList.length;i++){
                    if(this.contestList[i].id===this.Cform.contest_id){
                        return JSON.parse(this.contestList[i].group)
                    }
                }
                return []
          },
            contestd:function () {
                for(let i=0;i<this.contestList.length;i++){
                    if(this.contestList[i].id===this.Cform.contest_id){
                        return this.contestList[i]
                    }
                }
                return {}
            }
        },
        mounted() {
            this.$http.getData("information",(data)=>{
                this.Pform=data
            },()=>{
                this.$message.error("获取个人信息错误")
            })
            this.$http.getData("rcontest",(data)=>{
                this.contestList=data
            },()=>{
                this.$message.error("获取比赛信息错误")
            })
        }
    }
</script>

<style scoped lang="scss">
    .form2{
        padding: 50px;
    }
    .form-wrap3{
        position: relative;
        text-align: center;
        .avatar{
            margin: 10px;
            img{
                width: 200px;
            }
            position: absolute;
            left: 650px;

        }
        .form{
            font-size: 15px;
            text-align: start;
            display: inline-block;
            td{
                padding: 10px;
            }
        }
    }
    .form-wrap{
        text-align: center;
        .avatar{
            margin: 10px;
            img{
                width: 200px;
            }
            float:right;

        }
        .form{
            font-size: 30px;
            text-align: start;
            display: inline-block;
            td{
                padding: 20px;
            }
        }
    }
.progress{
    display: flex;
    .sub{
        border: 2px #000000 solid;
        text-align: center;
        flex: 1;
        font-size: 17px;
        height: 50px;
        line-height: 50px;
    }
    .sub_active{
        background-color: #000064;
        color: white;

    }
}
</style>