<template>
    <ul class="contestList">
        <li class="contest" v-for="contest in contestList" :class="{'active':contest.status===2,'over':contest.status===3}">
            <div class="contest_head">
                <p>{{contest.year}}年<em>{{contest.month}}</em>月</p>
                <p class="type">{{contest.desc}}</p>
                <span v-if="contest.status===1">暂未报名</span>
                <span v-else-if="contest.status===2">报名中>>>></span>
                <span v-else>报名已结束</span>
            </div>
            <div class="contest_detail">
                <div class="detail_main">
                    <p class="date">{{contest.year}}年<em>{{contest.month}}</em>月<em >{{contest.day}}</em>日举办</p>
                    <p>地址：{{contest.address}}</p>
                    <p class="main_button">
                        <el-button  v-if="contest.status===1" style="width: 100%;background: #000064;color: white" round>
                            暂未报名
                        </el-button>
                        <el-button  v-else-if="contest.status===2" style="width: 100%;background: #d75a00;color: white" round @click="$router.push({name:'Register'})">
                            报名中
                        </el-button>
                        <el-button v-else style="width: 100%;background: #666;color: white" round>
                            报名已结束
                        </el-button>
                    </p>
                </div>
                <div class="detail_sub">
                    <div class="sub_title">
                        <p>
                            网上报名时间
                        </p>
                    </div>
                    <div class="sub_body">
                        <p>{{contest.syear}}年<em>{{contest.smonth}}</em>月<em>{{contest.sday}}</em>日<em>{{contest.stime}}</em> ～
                            {{contest.eyear}}年<em>{{contest.emonth}}</em>月<em>{{contest.eday}}</em>日<em>{{contest.etime}}</em></p>
                    </div>
                </div>
            </div>
        </li>
    </ul>


</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
    data(){
        return{
            contestList:[]
        }
    },
  mounted() {
      this.$http.getData('icontest',(data)=>{
            this.contestList=data
          },()=>{
          this.$message.error("获取比赛信息失败，服务器维护中");
      })
  }
}
</script>
<style lang="scss" scoped>
    .contestList{
        list-style: none;
        margin: 0;
        padding:0;
        .contest {
            overflow: hidden;
            display: table;
            margin-top: 15px;
            padding: 0;
            width: 100%;
            border-radius: 5px;
            color: #222;
            font-size: 1em;
            text-align: center;
            vertical-align: middle;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            .contest_head{
                display: table-cell;
                width: 250px;
                margin: 0;
                padding: 23px 22px 30px;
                border-radius: 5px 0 0 5px;
                background: #000064;
                color: #fff;
                font-size: 20px;
                vertical-align: middle;
                line-height: 1.2;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                p{
                    margin-bottom: 5px;
                }
                em{
                    font-style: normal;
                    margin: 0 5px;
                    font-size: 1.6em;
                    font-weight: bold;
                }
                span{
                    display: block;
                    margin-top: 20px;
                    padding: 4px 3px;
                    border: 1px solid #fff;
                    font-size: 0.667em;
                    font-weight: bold;
                }
            }
            .contest_detail{
                display: table-cell;
                border: 4px solid #bbc8e6;
                border-left: none;
                border-radius: 0 5px 5px 0;
                text-align: left;
                vertical-align: middle;
                background: white;
                .detail_main{
                    display: table;
                    width: 100%;
                    padding: 17px 35px 17px 30px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;

                    .date{
                        margin-top: 0;
                        font-size: 30px;
                        line-height: 1.2;
                        em{
                            font-size: 1.5em;
                            font-style: normal;
                            font-weight: bold;
                        }
                    }
                    .main_button{
                        display: table-cell;
                        width: 240px;
                        vertical-align: middle;
                    }
                }
                .detail_sub{
                    display: table;
                    width: 100%;
                    margin-top: -7px;
                    padding: 15px 30px;
                    background: #eef1f6;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    .sub_title{
                        p{
                            padding: 7px 0 7px 15px;
                            background: #fff;
                            font-size: 18px;
                            line-height: 1.4;
                        }
                        &:after{
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 16px;
                            height: 100%;
                            background: url(../assets/schedule-vertical_bg01.png) no-repeat right center;
                            content: "";
                        }
                        position: relative;
                        display: table-cell;
                        width: 131px;
                        padding-right: 15px;
                        vertical-align: middle;
                    }
                    .sub_body{
                        display: table-cell;
                        padding-left: 18px;
                        vertical-align: middle;
                        em{
                            font-size: 1.428em;
                            font-style: normal;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .over{
            .contest_head{
                background: #666;
            }
        }
        .active{
            .contest_head{
                background: #d75a00 !important;
            }
        }

    }



</style>
